import { navigate } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { STRINGS } from '../../constants/strings';
import { URL } from '../../constants/urls';
import '../../css/components/_winston-form.scss';
import { validateEmail } from '../../utils/articleUtil';

const WinstonForm= (props) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [dob, setDob] = useState('');
  const [lifeInsurance, setLifeInsurance] = useState('');
  const [noInsurance, setNoInsurance] = useState(false);
  const [tnc, setTnC] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const refs = {
    firstName: useRef(null),
    lastName: useRef(null),
    email: useRef(null),
    phoneNumber: useRef(null),
    dob: useRef(null),
    lifeInsurance: useRef(null),
    noInsurance: useRef(null),
    tnc: useRef(null),
    marketing: useRef(null),
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    setFirstName(params.firstName || '');
    setLastName(params.lastName || '');
    setEmailAddress(params.email || '');
  }, []);

  const onActionClick = async (action) => {
    switch (action) {
      case 'yes':
        if (validateForm()) {
          setErrors({});
          setLoading(true);
          const name = `${firstName} ${lastName}`;
          const date = new Intl.DateTimeFormat('en-GB').format(dob).replaceAll('/', '-');

          const tags = `winston${noInsurance ? ',No_Insurance' : ''}`;
          // insurance provider field in vbout has 2 different id 933899 & 933897
          fetch(`${URL.SERVER}/subscribe?name=${name}&email=${emailAddress}&phone=${phoneNumber}&fields=933899:${lifeInsurance},933897:${lifeInsurance},934530:${date},645705:${phoneNumber}&marketing=${marketing}&website=${STRINGS.SUBSCRIPTION_TAG}&tag=${tags}&list=WINSTON`)

            .then(resp => resp.json())
            .then(res => {
              setLoading(false);
              setFirstName('');
              setLastName('');
              setEmailAddress('');
              setPhoneNumber(''); // Clear phone number
              setDob(''); // Clear date of birth
              setLifeInsurance('');
              setNoInsurance(false);
              setTnC(false);
              setMarketing(false);
              navigate(`/thankyou?returnUrl=/insurance/life-insurance/winston-over-50s-life-insurance`);
            });
        }
        break;
    }
  };

  const validateForm = () => {
    const newErrors = {};
    let firstErrorField = null;

    if (!firstName.trim()) {
      newErrors.firstName = 'Please enter first name';
      if (!firstErrorField) firstErrorField = refs.firstName.current;
    }
    if (!lastName.trim()) {
      newErrors.lastName = 'Please enter last name';
      if (!firstErrorField) firstErrorField = refs.lastName.current;
    }
    if (!emailAddress || !validateEmail(emailAddress)) {
      newErrors.email = 'Please enter a valid email address';
      if (!firstErrorField) firstErrorField = refs.email.current;
    }
    const ukPhoneRegex = new RegExp(/^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/);
    if (!ukPhoneRegex.test(phoneNumber)) {
      newErrors.phoneNumber = 'Please enter a valid phone number';
      if (!firstErrorField) firstErrorField = refs.phoneNumber.current;
    }
    if (!dob) {
      newErrors.dob = 'Please select your date of birth';
      if (!firstErrorField) firstErrorField = refs.dob.current;
    }
    if (!lifeInsurance && !noInsurance) {
      newErrors.lifeInsurance = 'Please provide your life insurance information or select "I don’t have life insurance"';
      if (!firstErrorField) firstErrorField = refs.lifeInsurance.current;
    }
    if (!tnc) {
      newErrors.tnc = 'Please accept Terms and Privacy Policy';
      if (!firstErrorField) firstErrorField = refs.tnc.current;
    }
    if (!marketing) {
      newErrors.marketing = 'To participate in the prize draw we need your consent to contact you';
      if (!firstErrorField) firstErrorField = refs.marketing.current;
    }

    setErrors(newErrors);
    if (firstErrorField && typeof firstErrorField.scrollIntoView === 'function') {
      firstErrorField.scrollIntoView({ behavior: 'smooth' });
      firstErrorField.focus();
    }

    return Object.keys(newErrors).length === 0;
  };

  return (
    <div className="winston-form">
      <div className="container">
        <h1>WIN! A £50 Amazon voucher! Enter now!</h1>
        <div className="input-container">
          <label htmlFor="firstName">First name</label>
          <input
            id="firstName"
            type="text"
            placeholder="Enter your first name"
            value={firstName}
            onChange={(event) => {
              setErrors({});
              setFirstName(event.target.value);
            }}
            ref={refs.firstName}
          />
          {errors.firstName && <div className="text-red">{errors.firstName}</div>}
          </div>
        <div className="input-container">
          <label htmlFor="lastName">Last name</label>
          <input
            id="lastName"
            type="text"
            placeholder="Enter your last name"
            value={lastName}
            onChange={(event) => {
              setErrors({});
              setLastName(event.target.value);
            }}
            ref={refs.lastName}
          />
          {errors.lastName && <div className="text-red">{errors.lastName}</div>}
        </div>
        <div className="input-container">
          <label htmlFor="email">Email address</label>
          <input
            id="email"
            type="email"
            placeholder="Enter your email address"
            value={emailAddress}
            onChange={(event) => {
              setErrors({});
              setEmailAddress(event.target.value);
            }}
            ref={refs.email}
          />
          {errors.email && <div className="text-red">{errors.email}</div>}
        </div>
        <div className="input-container">
          <label htmlFor="phoneNumber">Phone number</label>
          <input
            id="phoneNumber"
            type="text"
            placeholder="Enter your phone number"
            value={phoneNumber}
            onChange={(event) => {
              setErrors({});
              setPhoneNumber(event.target.value);
            }}
            ref={refs.phoneNumber}
          />
          <div className="text-sm">So Winston can contact you with your quote</div>
          {errors.phoneNumber && <div className="text-red">{errors.phoneNumber}</div>}
        </div>
        <div className="input-container dob-container">
          <label htmlFor="dob">Date of Birth</label>
          <div>
            <DatePicker
              id="dob"
              selected={dob ? new Date(dob) : null}
              onChange={(date) => {
                setErrors({});
                setDob(date);
              }}
              showYearDropdown
              showMonthDropdown
              maxDate={new Date()}
              dateFormat="dd-MM-YYYY"
              placeholderText="Select your date of birth"
              className="custom-datepicker"
              ref={refs.dob}
            />
          </div>
          <div className="text-sm">To confirm you're eligible for over 50s life insurance</div>
          {errors.dob && <div className="text-red">{errors.dob}</div>}
        </div>
        <div className="input-container">
          <label htmlFor="lifeInsurance">Who is your life insurance policy with?</label>
          <input
            id="lifeInsurance"
            type="text"
            placeholder="Enter your life insurance provider"
            value={lifeInsurance}
            onChange={(event) => {
              setErrors({});
              setLifeInsurance(event.target.value);
              setNoInsurance(false);
            }}
            disabled={noInsurance}
            ref={refs.lifeInsurance}
          />
        </div>
        <div className="checkbox-container -mt-2 mb-5">
          <input
            type="checkbox"
            id="noInsurance"
            className="life-insurance"
            checked={noInsurance}
            onChange={() => {
              setErrors({});
              setNoInsurance(!noInsurance);
              setLifeInsurance('');
            }}
            ref={refs.noInsurance}
          />
          <label htmlFor="noInsurance">I don’t have life insurance</label>
        </div>
        {errors.lifeInsurance && <div className="text-red -mt-5 mb-5">{errors.lifeInsurance}</div>}
        <div className="checkbox-container">
          <input
            type="checkbox"
            id="tnc"
            checked={tnc}
            onChange={() => {
              setErrors({});
              setTnC(!tnc);
            }}
            ref={refs.tnc}
          />
          <label htmlFor="tnc">I have read and accept the <a className="cursor-pointer underline" href="/winston-terms" target="_blank">Privacy Policy and Terms & Conditions</a>.</label>
        </div>
        {errors.tnc && <div className="text-red">{errors.tnc}</div>}
        <div className="checkbox-container">
          <input
            type="checkbox"
            id="marketing"
            checked={marketing}
            onChange={() => {
              setErrors({});
              setMarketing(!marketing);
            }}
            ref={refs.marketing}
          />
          <label htmlFor="marketing">I’m happy for AgeGroup and Winston to send me selected marketing information which may be of interest to me.</label>
        </div>
        {errors.marketing && <div className="text-red">{errors.marketing}</div>}
        <div className="action-container mt-3">
          <button
            onClick={() => onActionClick('yes')}
            type="button"
            className="bg-red py-4 px-12 text-white rounded-[19px]"
          >
            SUBMIT MY ENTRY {loading && <img alt="loading" src="/images/loading.svg" style={{ marginLeft: '5px' }} />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default WinstonForm;
